<template>
  <div>
    <login-page />
  </div>
</template>

<script>
import LoginPage from "../components/LoginPage";

export default {
  name: "Login",

  components: {
    LoginPage,
  },
};
</script>
