<template>
  <v-app id="inspire">
    <v-container fluid fill-height>
      <v-dialog v-model="loading" fullscreen>
        <v-container
          fluid
          fill-height
          style="background-color: rgba(255, 255, 255, 0.4)"
        >
          <v-layout justify-center align-center>
            <v-progress-circular
              indeterminate
              :size="60"
              :width="7"
              color="primary"
            >
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>

      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-alert v-show="errorAxios" class="mb-6" type="error">
              {{ $t("login.error_connection") }}
            </v-alert>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("login.login_form") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  @keyup.enter="login()"
                  :value="username"
                  v-model="user_email"
                  prepend-icon="mdi-account"
                  name="login"
                  :label="$t('login.login')"
                  :rules="emailRules"
                  required
                  type="text"
                ></v-text-field>
                <v-text-field
                  @keyup.enter="login()"
                  name="password"
                  autocomplete="on"
                  v-model="user_password"
                  :value="password"
                  :label="$t('login.enter_password')"
                  :append-icon="
                    value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  id="password"
                  prepend-icon="mdi-lock-outline"
                  :rules="requiredField"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login()">{{
                $t("login.login_button")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>


<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  data: () => ({
    value: "",
    password: "",
    username: "",
    user_password: "",
    user_email: "",
    actualLoading: 0,
    errorAxios: false,
    loading: false,
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Nesprávny tvar",
    ],
    requiredField: [(v) => !!v || "Toto pole je potrebné vyplniť"],
  }),

  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },

  methods: {
    ...mapMutations(["setUser", "setIsAdmin", "setToken", "removeToken"]),
    loadData() {
      this.removeToken();
    },
    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response.status == 403) this.$router.push("login").catch(() => {});
      this.completeLoadingBlock();
    },
    login() {
      this.setUser(this.username);
      let body = {
        email: this.user_email,
        password: this.user_password,
      };
      var optionAxios = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf8",
        },
      };
      this.setLoadingBlock();
      axios
        .post(
          process.env.VUE_APP_URL + process.env.VUE_APP_LOGIN,
          body,
          optionAxios
        )
        .then((response) => {
          this.setToken(response.data.jwt);
          this.setIsAdmin(response.data.admin);
          this.completeLoadingBlock();
          if (response.data.firstLogin) {
            this.$router.push("/registration").catch(() => {});
          } else this.$router.push("/").catch(() => {});
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
  },
};
</script>

<style></style>